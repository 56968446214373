<!-- @format -->

<template>
  <div class="flex flex-col gap-20 justify-between">
    <div class="flex flex-col gap-10">
      <div class="flex gap-5 items-center">
        <div
          class="material-icons text-teal text-5xl bg-gray-200 bg-opacity-50 rounded-lg p-2.5"
        >
          person
        </div>
        <div class="flex flex-col">
          <div class="text-3xl font-medium">
            <span v-if="!isPreview">Add</span> Doctor
          </div>
          <div class="text-lg font-thin text-gray-500">
            <span v-if="!isPreview">Enter</span> Doctor Details
          </div>
        </div>
      </div>
      <div class="bg-white rounded-xl py-10 px-8">
        <div class="text-2xl">Doctor Information</div>

        <div class="mt-5">
          <ViewDoctor
            :practices="practices"
            :status="[
              { name: 'Active', id: 1 },
              { name: 'Retired', id: 2 },
              { name: 'Pending Verification', id: 0 },
            ]"
            :key="practices"
            :doctorProfile="isPreview ? doctorProfile : null"
            :isPreview="parseInt(isPreview) == 1 ? true : false"
            :isLoading="loadingDoctor"
            @on-field-change="updateDoctorField"
            @form-validity-change="handleFormValidityChange"
          />
        </div>
      </div>
      <div
        v-if="showSignaturePad"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      >
        <div class="bg-white p-4 rounded-md shadow-lg">
          <div class="flex justify-end">
            <button
              class="material-icons bg-red-500 rounded-full text-white w-6"
              @click="showSignaturePad = false"
            >
              close_outlined
            </button>
          </div>
          <SignaturePad
            @close="showSignaturePad = false"
            @at-save-signature="saveSignature"
            :signee="signeeName"
            :isEdited="isEditMode"
            :key="signeeName"
          />
        </div>
      </div>
      <div class="bg-white rounded-xl py-10 px-8">
        <div class="text-2xl">Digital Signature Preview</div>
        <div class="mt-5">
          <PreviewEditSignature
            :defaultSignature="defaultSignature"
            :addedSignature="
              parseInt(isPreview) == 1
                ? doctorProfile?.doctor_signature
                  ? doctorProfile?.doctor_signature
                  : signatureImageUrl
                : signatureImageUrl
            "
            @add-signature-clicked="openSignaturePad"
            @remove-signature-clicked="removeSignature"
            :isLoading="loadingDoctor"
            :isPreview="parseInt(isPreview) == 1 ? true : false"
            :key="doctor"
          />
        </div>
      </div>
    </div>
    <div>
      <div v-if="emailError" class="text-red-500 mb-4">{{ emailError }}</div>
      <button
        class="bg-teal p-4 text-xl rounded-lg text-white w-full font-medium"
        :class="isFormValid ? 'bg-teal' : 'bg-teal bg-opacity-25'"
        :disabled="!isFormValid"
        @click="addDoctor"
      >
        {{ parseInt(isPreview) == 0 ? "Add Doctor" : "Save Changes" }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import ViewDoctor from "../../../components/newUi/MedicalLetters/ViewDoctor.vue";
import PreviewEditSignature from "../../../components/newUi/MedicalLetters/PreviewEditSignature.vue";
import SignaturePad from "@/components/newUi/atoms/SignaturePad.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
const store = useStore();
const showSignaturePad = ref(false);
const signature = ref("");
const isEditMode = ref(false);
const signatureLoader = ref(false);
const signatureFile = ref("");
const isAddedSignature = ref(false);
const router = useRouter();
const route = useRoute();
const isPreview = ref(route.params.isView);
const doctorId = ref(route.params.doctorId);

const doctorProfile = computed(() => {
  return store.getters["PMLModule/getDoctorProfile"];
});
const emailError = computed(() => store.getters["PMLModule/getEmailError"]);
const loadingDoctor = ref(true);

const practices = ref([]);

const doctor = ref({
  first_name: "",
  last_name: "",
  gmc_number: "",
  doctor_qualification: "",
  practice: "",
  gmc_status: "",
  signature: "",
  role: "",
});
const signatureImageUrl = ref("");

const isFormValid = ref(false);

const handleFormValidityChange = (valid) => {
  isFormValid.value = valid;
};

const openSignaturePad = () => {
  showSignaturePad.value = true;
};
const saveSignature = (signatureValue) => {
  signature.value = signatureValue;
  signatureLoader.value = true;

  signatureImageUrl.value = signatureValue.signatureImage;
  signatureFile.value = signatureValue.signatureImageFile;
  if (parseInt(isPreview.value) == 1) isFormValid.value = true;
  showSignaturePad.value = false;
  isAddedSignature.value = true;
};

const updateDoctorField = ({ fieldName, value }) => {
  clearEmailError();
  doctor.value[fieldName] = value;
};

const addDoctor = () => {
  if (parseInt(isPreview.value) == 0) {
    if (isAddedSignature.value) {
      const formData = new FormData();
      formData.append("first_name", doctor.value.first_name);
      formData.append("last_name", doctor.value.last_name);

      formData.append("gmc_number", doctor.value.gmc_number);
      formData.append(
        "doctor_qualification",
        doctor.value.doctor_qualification
      );
      formData.append("role", doctor.value?.role);
      formData.append("practice", doctor.value.practice);
      formData.append("gmc_status", doctor.value.gmc_status);
      formData.append("doctor_signature", signatureFile.value);
      formData.append("email", doctor.value.email);
      store.dispatch("PMLModule/createDoctorProfile", formData).then(() => {
        router.push({
          name: "hq-medicalLettersDashboard",
        });
      });
    } else {
      store.dispatch("PMLModule/createDoctorProfile", doctor.value).then(() => {
        router.push({
          name: "hq-medicalLettersDashboard",
        });
      });
    }
  } else {
    if (isAddedSignature.value) {
      const formData = new FormData();
      formData.append("doctor", doctorId.value);
      formData.append(
        "gmc_status",
        doctor.value.gmc_status
          ? doctor.value.gmc_status
          : doctorProfile.value?.gmc_status
      );
      formData.append("doctor_signature", signatureFile.value);
      store.dispatch("PMLModule/updateDoctorProfile", formData).then(() => {
        router.push({
          name: "hq-medicalLettersDashboard",
        });
      });
    } else {
      const payload = {
        doctor: doctorId.value,
        gmc_status: doctor.value.gmc_status
          ? doctor.value.gmc_status
          : doctorProfile.value?.gmc_status,
      };
      store.dispatch("PMLModule/updateDoctorProfile", payload).then(() => {
        router.push({
          name: "hq-medicalLettersDashboard",
        });
      });
    }
  }
};

const removeSignature = () => {
  signatureImageUrl.value = "";
  isAddedSignature.value = false;
};

const defaultSignature = computed(() => {
  return parseInt(isPreview.value) == 1
    ? doctorProfile.value?.first_name +
        " " +
        doctorProfile.value?.last_name?.charAt(0).toUpperCase() +
        "."
    : doctor.value.first_name +
        " " +
        doctor.value.last_name.charAt(0).toUpperCase() +
        ".";
});

const clearEmailError = () => {
  if (emailError.value) {
    store.state.PMLModule.emailError = "";
  }
};

onMounted(async () => {
  clearEmailError();
  if (parseInt(isPreview.value) === 0)
    await store.dispatch("hqComplaints/loadPractices").then((response) => {
      practices.value = response;
      loadingDoctor.value = false;
    });
  else {
    loadingDoctor.value = true;
    await store
      .dispatch("PMLModule/fetchDoctorProfile", { doctorId: doctorId.value })
      .then(() => {
        loadingDoctor.value = false;
      });
  }
});
</script>
